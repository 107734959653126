import { useRouter } from 'next/router';
import { ReactNode } from 'react';
import { BreadcrumbDynamic } from '@org-crowley/enterprise-react-component-library';
import { useCustomer} from '../contexts/CustomerContext';

export interface LayoutContentsProps {
  children: ReactNode | ReactNode[];
}

export function LayoutContents({ children }: LayoutContentsProps) {
  const { customer } = useCustomer();
  const router = useRouter();

  return (
    <>
      <BreadcrumbDynamic className="py-4" urlPath={router.pathname} itemTitle={customer ? customer.name : 'Customer Name'} />
      {/* children should be <Component from app.tsx */}
      <main className="px-6 py-6">
        {children}
      </main>
    </>
  );
}

import '../styles/globals.css';
import 'mapbox-gl/dist/mapbox-gl.css';
import type { AppProps } from 'next/app';
import Head from 'next/head';
import { OktaProvider } from '../components/OktaContext';
import { Layout } from '@org-crowley/enterprise-react-component-library';
import { useRouter } from 'next/router';
import { useState } from 'react';
import { FeatureFlagProvider } from '../components/FeatureFlagContext';
import { LayoutContents } from '../components/LayoutContents';
import { CustomerProvider } from '../contexts/CustomerContext';

export interface NavTab {
  displayName: string;
  pathString: string;
}

function DemoApp({ Component, pageProps }: AppProps) {
  const [userName, setUserName] = useState('');
  const router = useRouter();

  return (
    <>
      <Head>
        <title>Enterprise React Demo</title>
      </Head>
      <OktaProvider setUserName={setUserName} userName={userName}>
        <CustomerProvider>
          <FeatureFlagProvider>
            <Layout
              masthead={{
                title: 'Enterprise React Demo',
                onLogoClick: () => {
                  router.push('/');
                },
                name: userName
              }}
            >
              <LayoutContents>
                <Component {...pageProps}></Component>
              </LayoutContents>
            </Layout>
          </FeatureFlagProvider>
        </CustomerProvider>
      </OktaProvider>
    </>
  );
}

export default DemoApp;
